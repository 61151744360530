import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "managing up",
  "author": "tzookb",
  "type": "post",
  "date": "2022-06-20T13:50:08.000Z",
  "url": "/managing-up/",
  "featuredImage": "./manage.jpg",
  "desc": "how to manage yourself, and mange your managers",
  "categories": ["management", "soft-skills"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This great content on "managing up" is copied from an amazing thread I read on twitter.
The author is `}<a parentName="p" {...{
        "href": "https://twitter.com/wes_kao"
      }}>{`Wes Kao Twitter`}</a>{` and I surely recommend following her.`}</p>
    <h2>{`Thread Content`}</h2>
    <p>{`Here’s a secret about managing up:`}</p>
    <p>{`The more you manage up, the more your boss will recognize your value.`}</p>
    <p>{`It’s a Jedi mind trick.`}</p>
    <p>{`Let your boss think they’re managing you… But in reality, YOU are managing THEM.`}</p>
    <p>{`Here are 13 principles for managing up:
If you don’t manage up, you’ll waste time on:`}</p>
    <p>{`· Needless back-and-forth
· Avoidable misunderstandings
· Confusion on how you’re adding value`}</p>
    <p>{`Work is hard enough as it is. Not managing up makes it harder. `}</p>
    <p>{`Luckily, it's easy to start managing up & you'll see immediate benefits.`}</p>
    <p>{`Your boss will believe you’re`}</p>
    <p>{`✔️ Adding a ton of value
✔️ Worth investing in
✔️ Worth advocating for`}</p>
    <p>{`They’ll feel positive, warm, and grateful for you.`}</p>
    <p>{`It's win-win.`}</p>
    <p>{`Here's how to do it: `}</p>
    <h3>{`Embrace managing up.`}</h3>
    <p>{`🚫 “It’s my boss' job to manage me. I’ll manage up when I’m promoted.”`}</p>
    <p>{`✅ “I shouldn’t have to manage up. But if I do, I'll take control & further my career.”`}</p>
    <p>{`It might not be fair, but the truth is you get promoted once you’ve already been doing the job. `}</p>
    <h3>{`Make the best use of time & focus on the punchline.`}</h3>
    <p>{`🚫 “This happened, then this other thing, this important thing that’s completely buried, and then this other thing.”`}</p>
    <p>{`✅ “Here’s the minimum backstory, so we can spend time on the juicy stuff.”`}</p>
    <p>{`Unroll available on Thread Reader
`}<a parentName="p" {...{
        "href": "https://twitter.com/wes_kao/status/1467161815159300106"
      }}>{`https://twitter.com/wes_kao/status/1467161815159300106`}</a></p>
    <h3>{`Show your thought process.`}</h3>
    <p>{`🚫 “I recommend this.”`}</p>
    <p>{`✅ “I recommend this because X. My criteria/assumptions were A and B. The potential trade-off is Y, but seems manageable because Z. I vetted a range of options `}{`[insert options]`}{` and found `}{`[my recommendation]`}{` best fits our needs.” `}</p>
    <h3>{`Offer solutions and flag issues.`}</h3>
    <p>{`🚫 “This is a problem.”`}</p>
    <p>{`✅ “This is a problem. I suggest doing X.”`}</p>
    <p>{`✅ “This is a problem. I’m not sure how to solve, but wanted to flag it. My hunch is to monitor for now because X, but let me know if we should brainstorm what to do.” `}</p>
    <h3>{`Turn complaints into opportunities.`}</h3>
    <p>{`🚫 “This process is terrible.”`}</p>
    <p>{`✅ “This process isn't ideal because X. I’d like to update to Y process instead, which should be cheaper/faster/easier. Do you agree if I make the change this week?” `}</p>
    <h3>{`Use information hierarchy.`}</h3>
    <p>{`Put your recommendation at the top, then context below.`}</p>
    <p>{`This allows folks to read as much or little of the context as they need.`}</p>
    <p>{`🚫 `}{`[Action items, backstory, bottlenecks, and context all jumbled together]`}</p>
    <p>{`✅ 👇`}</p>
    <h3>{`Keep your boss in the loop.`}</h3>
    <p>{`🚫 `}{`[Silence]`}</p>
    <p>{`✅ “We have 2 weeks until the deadline. We’re at 33/50 students, and with X leads in the pipeline, it's not enough to hit our goal. So this week, I’ll reach out to 100 new leads. I'd love to get your input on Y--could we review tmrw?” `}</p>
    <h3>{`Control your own actions.`}</h3>
    <p>{`🚫 “Micromanagers are the worst. I wish they'd get off my back and just let me do my job.”`}</p>
    <p>{`✅ “I wonder what’s making them feel the need to micromanage? I'll review how I’m keeping them informed & what success looks like so I can show I’m handling it.”
You might have a micromanager… Or you might have a boss who knows they’re ultimately responsible for anything their team does (including your work).`}</p>
    <p>{`They want to make sure projects are on track & their team’s output meets the bar for excellence.`}</p>
    <p>{`Help them help you. `}</p>
    <h3>{`Proactively assert what to do.`}</h3>
    <p>{`🚫 “What should I do?”`}</p>
    <p>{`✅ “I recommend X. Here's rationale, data, or examples of why this will work. The risk is Y but we can address by Z. If you agree, I’ll start by doing `}{`[first step]`}{`.”
Don’t only ask questions. Share your point of view too.`}</p>
    <p>{`A great question can be game-changing, but don't expect to ask "why" and think it'll change everything.`}</p>
    <p>{`🚫 “Why? `}{`[Mic drop]`}{`”`}</p>
    <p>{`✅ “Why? My POV is `}{`[insight]`}{`. I'm not 100% sure yet. Curious how ya’ll are thinking about it." `}</p>
    <h3>{`Take charge of the process.`}</h3>
    <p>{`🚫 “I’ll wait until my boss tells me to manage up more.”`}</p>
    <p>{`✅ “My boss is busy with their job, plus a bunch of worries I don’t know about. They might feel awkward asking me to manage up more, so I'll take the reins & manage up proactively."
Know when to get out.`}</p>
    <p>{`🚫 “My boss undermines my work, throws me under the bus, and blames me for no reason. Managing up doesn’t work.”`}</p>
    <p>{`✅ “Managing up works, but not in a toxic environment. I'll find an org that’s a better fit AND manage up to a boss who’s eager to work w/me.” `}</p>
    <h3>{`Expect to manage up forever.`}</h3>
    <p>{`🚫 “I’ll only have to manage up when I’m junior.”`}</p>
    <p>{`✅ “I’m ready to manage up even if I’m 15+ years into my career.”`}</p>
    <p>{`You might assume increased seniority means less managing up.`}</p>
    <p>{`Ironically it's the opposite. Senior folks are often BEST at it. `}</p>
    <hr></hr>
    <p>{`That’s it for today.`}</p>
    <p>{`If you found this valuable:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Follow me for threads on leadership, education, marketing → `}<a parentName="p" {...{
            "href": "https://twitter.com/wes_kao"
          }}>{`@wes_kao`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Ready to build your own course? Check out `}<a parentName="p" {...{
            "href": "https://twitter.com/MavenHQ"
          }}>{`@MavenHQ`}</a>{` and `}<a parentName="p" {...{
            "href": "https://maven.com/"
          }}>{`maven.com`}</a>{` to get started`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      